import * as DialogPrimitive from "@radix-ui/react-dialog";
import { X as IconX } from "lucide-react";
import * as React from "react";
import { Button } from "../../core/Button";
import { VStack } from "../../core/Stack";
import { cn } from "../../utils";

// See https://ui.shadcn.com/docs/components/dialog for reference
// See https://tailwindui.com/components/application-ui/overlays/modals#component-31555aec01de5027d6696629eb60f673 for styling

const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogPortal = DialogPrimitive.Portal;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "tw-fixed tw-inset-0 tw-z-[16777276] tw-bg-black/30 tw-backdrop-blur-sm data-[state=open]:tw-animate-in data-[state=closed]:animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0 dark:tw-bg-zinc-950/80 tw-pointer-events-auto",
      className,
    )}
    {...props}
    // @radix-ui/dialog hard-codes `pointerEvents: 'auto'`, which is not
    // SSR+CSP compatible, so we undefine it here, and replace it with a
    // tailwind className `tw-pointer-events-auto`.
    style={{ pointerEvents: undefined, ...props.style }}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "tw-fixed tw-z-[16777277] tw-rounded-md tw-left-[50%] tw-translate-x-[-50%] tw-grid tw-w-full tw-max-w-lg tw-gap-4 tw-bg-white dark:tw-bg-rad-blue-700 tw-p-6 tw-shadow-xl tw-transition-all tw-duration-200 data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0 data-[state=closed]:tw-zoom-out-95 data-[state=open]:tw-zoom-in-95 data-[state=closed]:tw-slide-out-to-left-1/2 data-[state=closed]:tw-slide-out-to-top-[48%] data-[state=open]:tw-slide-in-from-left-1/2 data-[state=open]:tw-slide-in-from-top-[48%] md:tw-w-full tw-pointer-events-auto",
        className,
      )}
      {...props}
      // @radix-ui/dialog hard-codes `pointerEvents: 'none'`, which is not
      // SSR+CSP compatible, so we undefine it here, and replace it with a
      // tailwind className `tw-pointer-events-auto`.
      style={{ pointerEvents: undefined, ...props.style }}
    >
      {children}
      <DialogPrimitive.Close asChild={true}>
        <Button
          variant="tertiary"
          size="sm"
          colorScheme="zinc"
          title="Close"
          className="tw-absolute tw-right-6 tw-top-6 tw-h-6 tw-w-6 !tw-p-[.25rem]"
        >
          <IconX />
        </Button>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <VStack spacing={1} {...props} />
);
DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({
  className,
  children,
  withDialogCloseButton = true,
  dialogCloseButtonText = "Close",
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  dialogCloseButtonText?: string;
  withDialogCloseButton?: boolean | React.ReactNode;
}) => {
  const isCustomClose = typeof withDialogCloseButton !== "boolean";
  return (
    <div
      className={cn(
        "tw-flex tw-flex-col-reverse sm:tw-flex-row sm:tw-justify-end sm:tw-space-x-2",
        className,
      )}
      {...props}
    >
      {children}
      {withDialogCloseButton && (
        <DialogPrimitive.Close asChild={true}>
          {isCustomClose ? (
            withDialogCloseButton
          ) : (
            <Button
              variant="tertiary"
              colorScheme="zinc"
              className={
                !children
                  ? "tw-w-full dark:tw-bg-rad-blue-400 dark:hover:tw-bg-rad-blue-300 dark:tw-text-rad-blue-100"
                  : ""
              }
            >
              {dialogCloseButtonText}
            </Button>
          )}
        </DialogPrimitive.Close>
      )}
    </div>
  );
};
DialogFooter.displayName = "DialogFooter";

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "tw-text-xl tw-font-medium tw-leading-none tw-tracking-tight dark:tw-text-white",
      className,
    )}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn(
      "tw-text-sm tw-text-zinc-500 dark:tw-text-rad-blue-100 tw-m-0",
      className,
    )}
    {...props}
  />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
